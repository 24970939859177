import React from 'react';
import { Button, Heading } from 'react-aria-components';
import I18n from '@/modules/i18n';
import { ExpandCollapseButton } from '@/components/staffer/UnitPlanningDrawer/ExpandCollapseButton';
import { PrintPdfButton } from '@/components/staffer/UnitPlanningDrawer/PrintPdfButton';

interface UnitPlanningHeaderProps {
  isExpanded: boolean;
  printPdfPath: string;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UnitPlanningHeader = ({
  isExpanded,
  printPdfPath,
  setIsExpanded,
  setIsOpen,
}: UnitPlanningHeaderProps) => (
  <Heading className="tw-p-4 tw-border-b tw-border-solid tw-border-med-grey" slot="title">
    <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
      <h2>{I18n.t('unit_planning')}</h2>
      <div className="tw-flex tw-items-center tw-gap-4">
        <ExpandCollapseButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
        <PrintPdfButton printPdfPath={printPdfPath} />
        <Button
          aria-label={I18n.t('close_dialog')}
          className="tw-bg-white tw-outline-none tw-border-none tw-text-xl tw-cursor-pointer"
          slot="close"
          onPress={() => setIsOpen(false)}
        >
          ✕
        </Button>
      </div>
    </div>
    <p className="tw-text-dark-grey tw-text-sm tw-mb-0">{I18n.t('not_visible_to_students')}.</p>
  </Heading>
);
