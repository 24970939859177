import React from 'react';
import * as Routes from '@/modules/routes';
import Modal, { Footer } from 'components/common/Modal';
import I18n from 'i18n-js';
import { UnitType, LessonType } from '../types';
import styles from './index.module.scss';

interface TableOfContentsModalProps {
  units: UnitType[];
  isOpen: boolean;
  closeModal: () => void;
}

const TableOfContentsModal = ({ units, isOpen, closeModal }: TableOfContentsModalProps) => {
  const getUnitPath = (unit: UnitType) => Routes.shared_program_unit_path(unit.program_id, unit.id);
  const getLessonPath = (unit: UnitType, lesson: LessonType) => Routes.shared_program_lesson_slide_shows_path(unit.program_id, lesson.id);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      headerText={I18n.t('table_of_contents')}
      preventScroll
    >
      <ul>
        {
          units.map(unit => (
            <div key={unit.id} className="tw-flex tw-mb-5 tw-leading-loose">
              <li className="tw-w-full">
                <div className="tw-flex tw-items-center">
                  <img className={styles.tocImage} src={unit.poster_url} alt={unit.title} />
                  <a
                    className="tw-text-tci-purple tw-font-bold tw-text-xl tw-ml-4"
                    href={getUnitPath(unit)}
                  >
                    {unit.title_with_position}
                  </a>
                </div>
                <div className="tw-flex tw-m-[auto] tw-w-[70%]">
                  <ul>
                    {unit.lessons.map(lesson => (
                      <li key={lesson.id}>
                        <a href={getLessonPath(unit, lesson)}>{lesson.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            </div>
          ))
        }
      </ul>
      <Footer
        primaryButtonCallback={() => {
          closeModal();
        }}
        primaryButtonClassName="btn btn--purple"
        primaryButtonSubmit={false}
        primaryButtonText={I18n.t('close')}
      />
    </Modal>
  );
};

export default TableOfContentsModal;
