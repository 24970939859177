import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { renderErrors } from 'common/Forms/Utils';
import showToast from 'common/Toast';
import GradesForm from './GradesForm';
import { handleRadio } from '../RadioGroup';
import { handleSelect } from '../Util';
import SubmitButton from 'common/Forms/SubmitButton';
import { SubmitError } from 'common/Forms/Utils';

export const gradesDefaultSettings = {
  grade_notebooks_by: 'section',
  grade_notebooks_by_lesson: false,
  notebook_possible_points: 5,
  section_possible_points: 5,
  student_challenge_highlight: 50,
  trends_highlight: 50
};

export default class Grades extends Component {
  static propTypes = {
    active: PropTypes.bool,
    settings: PropTypes.shape({
      grade_notebooks_by_lesson: PropTypes.bool,
      notebook_possible_points: PropTypes.string,
      student_challenge_highlight: PropTypes.string,
      trends_highlight: PropTypes.string
    }),
    stafferPath: PropTypes.string.isRequired,
    updateState: PropTypes.func
  };

  static defaultProps = {
    active: false,
    settings: { ...gradesDefaultSettings },
    updateState: () => {}
  };

  constructor(props) {
    super(props);

    this.state = { submitting: false };
  }

  handleSubmit = async (values = {}) => {
    this.setState({ submitting: true });
    const updateValues = {
      ...values,
      grade_notebooks_by: values.grade_notebooks_by_lesson ? 'lesson' : 'section'
    }

    return Axios
      .put(this.props.stafferPath, updateValues)
      .then((response) => {
        showToast('Save successful');
        this.props.updateState({ settings: response.data.data.settings });
        this.setState({ submitting: false });
      })
      .catch(error => this._handleSubmitError(error));
  };

  _getInitialValues() {
    return Object.assign({}, gradesDefaultSettings, this.props.settings);
  }

  _handleSubmitError(error) {
    this.setState({ submitting: false });
    return { [FORM_ERROR]: renderErrors(error.response.data.errors) };
  }

  render() {
    if (!this.props.active) return null;

    return (
      <div>
        <Form
          onSubmit={this.handleSubmit}
          initialValues={this._getInitialValues()}
          mutators={{
            handleRadio: handleRadio,
            handleSelect: handleSelect
          }}
          render={({
            handleSubmit, submitError, form, values
          }) => (
            <form onSubmit={handleSubmit}>
              <GradesForm form={form} />

              <SubmitError error={submitError} />
              <hr />

              <div className="tw-text-right">
                <SubmitButton
                  submitButtonText="Update"
                  submitting={this.state.submitting}
                />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}
