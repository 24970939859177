import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import moment from 'moment-timezone';
import { composeValidators, validDate } from '../../../common/Forms/Validators';
import Section from '../Section';
import sectionStyles from '../Section.module.scss';
import Tooltip from '../../../common/Tooltip/index';
import DateTimePicker from './DateTimePicker';
import { assignmentInitialValuesPropType } from '../PropTypes';
import styles from './WhenSection.module.scss';

export default class WhenSection extends Component {
  MODEL_TYPES = {
    biographies: 'Biography Libraries',
    careerProfiles: 'Career Profile Libraries',
    civics: 'Civics Libraries',
    classroomActivity: 'Classroom Activities',
    investigationActivity: 'Investigation Activities',
    lessonOutline: 'Lesson Outlines',
    maps: 'Map Libraries',
    primarySources: 'Primary Source Libraries',
    supremeCourtCases: 'Supreme Court Case Libraries',
    scienceAndEngineering: 'Science and Engineering Libraries',
    unitProjects: 'Unit Projects',
    videoActivity: 'Video Activities',
  };

  static propTypes = {
    formHandleSelect: PropTypes.func.isRequired,
    initialValues: assignmentInitialValuesPropType,
    modelType: PropTypes.string,
    newRecord: PropTypes.bool,
  };

  static defaultProps = {
    initialValues: {},
    modelType: '',
    newRecord: true
  };

  constructor(props) {
    super(props);

    this.state = {
      modelType: this.props.initialValues.modelType,
      showFutureDateWarning: false,
      showStartDatePicker: !this.props.newRecord,
    };

    this.handleEnforceDatesChange = this.handleEnforceDatesChange.bind(this);
    this.showStartDatePicker = this.showStartDatePicker.bind(this);
    this.setShowFutureDateWarning = this.setShowFutureDateWarning.bind(this);
    this.setModelType = this.setModelType.bind(this);
  }

  componentDidUpdate(prevProps) {
    // If modelType passed is not the same as the current state, i.e. the type has just been changed.
    if (this.props.modelType !== this.state.modelType) {
      if (this.props.modelType === 'unitProjects' || this.props.modelType === 'videoActivity') {
        // Uncheck "Enforce Due Date" box.
        this.props.formHandleSelect(false, 'dates_enforced');
      }
      this.setModelType();
    }

    const oldStartTime = prevProps.initialValues.start_datetime;
    const newStartTime = this.props.initialValues.start_datetime;

    if (newStartTime && (newStartTime !== oldStartTime)) {
      this.setShowFutureDateWarning({ value: this.props.initialValues.start_datetime });
    }
  }

  setModelType() {
    this.setState({ modelType: this.props.modelType });
  }

  handleEnforceDatesChange(clickEvent) {
    this.props.formHandleSelect(clickEvent.target.value, 'dates_enforced');
  }

  showStartDatePicker() {
    this.setState({ showStartDatePicker: true });
  }

  setShowFutureDateWarning(datetime) {
    const showFutureDateWarning = moment.utc(datetime.value, 'YYYY-MM-DD HH:mm:ss').isAfter();

    this.setState({ showFutureDateWarning });
  }

  renderStartDateField() {
    if (this.state.showStartDatePicker) {
      return (
        <div>
          {this.state.showFutureDateWarning && (
            <div className={styles.notice}>
              <i aria-hidden="true" className={`fa fa-warning ${styles.warning}`} />
              {' '}
              <span>Students will not have access to the assigned content until the available date/time.</span>
            </div>
          )}
          <DateTimePicker
            formHandleSelect={this.props.formHandleSelect}
            initialValues={this.props.initialValues}
            label="Available Date"
            fieldName="start_datetime"
            required={false}
            timeBuffer={0}
            callback={this.setShowFutureDateWarning}
            validator={validDate}
          />
        </div>
      );
    }

    return (
      <div className={`${sectionStyles.fieldContainer} mt10 mb15`}>
        <div className={sectionStyles.label}>
          Available Date
        </div>

        <div className={styles.pickerContainer}>
          Immediately
          <button
            type="button"
            className={`btn btn--sm btn--link-purple ${styles.editStartDate}`}
            onClick={this.showStartDatePicker}
          >
            <i className="fa fa-pencil-square-o" aria-label="Edit available date" />
            &nbsp;
            Schedule for Later
          </button>
        </div>
      </div>
    );
  }

  isDisabled() {
    switch (this.state.modelType) {
      case 'biographies':
      case 'careerProfiles':
      case 'civics':
      case 'classroomActivity':
      case 'investigationActivity':
      case 'lessonOutline':
      case 'maps':
      case 'primarySources':
      case 'scienceAndEngineering':
      case 'supremeCourtCases':
      case 'unitProjects':
      case 'videoActivity':
        return true;
      default:
        return false;
    }
  }

  hoverMessage() {
    if (!this.isDisabled()) return '';

    return `Enforce Due Date is not available for ${(this.MODEL_TYPES)[this.state.modelType]}`;
  }

  renderEnforceDates() {
    return (
      <div className={`${sectionStyles.fieldContainer} mt5`}>
        <div className={`${sectionStyles.spacer} inline_block`} />
        <Tooltip
          content={this.hoverMessage()}
          shouldDisplay={this.isDisabled()}
        >
          <div>
            <Field
              component="input"
              type="checkbox"
              id="enforce-dates"
              name="dates_enforced"
              defaultChecked={this.props.initialValues.dates_enforced}
              onClick={this.handleEnforceDatesChange}
              className="ml0"
              disabled={this.isDisabled()}
            />
          </div>
        </Tooltip>
        <label htmlFor="enforce-dates">
          <i aria-hidden="true" className="fa fa-lock" />
          {' Enforce Due Date'}
          <Tooltip
            content="Students cannot submit assignment after due date."
          >
            <span>
              <i className="fa fa-question-circle ml5" aria-hidden="true" />
              <span className="sr-only">More information</span>
            </span>
          </Tooltip>
        </label>
      </div>
    );
  }

  render() {
    return (
      <Section title="When">
        {this.renderStartDateField()}

        <DateTimePicker
          formHandleSelect={this.props.formHandleSelect}
          initialValues={this.props.initialValues}
          label="Due Date"
          fieldName="due_datetime"
          validator={validDate}
        />

        <div className={`${sectionStyles.timeZone}`}>
          <div className="inline_block">Timezone: {moment.tz(moment.tz.guess()).zoneAbbr()}</div>
        </div>

        {this.renderEnforceDates()}
      </Section>
    );
  }
}
