import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useSlideShowQuestionGroups from '@/hooks/api/useSlideShowQuestionGroups';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { toCamelCaseDeep } from '@/modules/TCIUtils';
import SectionNotebookSelects from './SectionNotebookSelects';
import UnitNotebookSelects from './UnitNotebookSelects';
import LessonNotebookSelects from './LessonNotebookSelects';
import styles from '../index.module.scss';
import { slideObjectPropTypes } from '../../../../Utils';

type QuestionSelectFieldsProps = {
  lessonId?: number,
  modelId: string;
  modelType: string;
  programId: number,
  sectionId: number,
  setHasError: (error: boolean) => void,
  setNotebookId: (notebookId: number) => void,
  setSectionId: (sectionId: number) => void,
  setTypeOption: (typeOption: string) => void,
  slideObject: slideObjectPropTypes,
  typeOption: string,
  unitId?: number,
  notebookId?: number,
};

const BASE_NOTEBOOK_TYPE_OPTIONS = [
  { label: 'Unit Project', value: 'Unit Project' },
  { label: 'Activity Notebook', value: 'Activity Notebook' },
  { label: 'Reading Notebook', value: 'Section' },
];

const QuestionSelectFields = ({
  lessonId,
  modelId,
  modelType,
  programId,
  setHasError,
  unitId,
  setNotebookId,
  sectionId,
  setSectionId,
  slideObject,
  typeOption,
  setTypeOption,
  notebookId,
}: QuestionSelectFieldsProps) => {
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const [notebookTypeOptions, setNotebookTypeOptions] = useState(BASE_NOTEBOOK_TYPE_OPTIONS);

  const params = new URLSearchParams([
    ['id', slideShowId.toString()],
    ['model_id', modelId],
    ['model_type', modelType],
  ]);

  const { data, isLoading } = useSlideShowQuestionGroups({ params });

  useEffect(() => {
    if (data.length === 0) return;

    const shouldAddThisSlideshow = data.some(qg => qg.questions.length > 0);

    if (!shouldAddThisSlideshow) return;

    setNotebookTypeOptions([...BASE_NOTEBOOK_TYPE_OPTIONS, { label: 'This Slideshow', value: 'Slide Show' }]);

    if (!slideObject || !modelId) return;

    const htmlElement = toCamelCaseDeep(slideObject.htmlElements.find(e => parseInt(e.id, 10) === parseInt(modelId, 10)));

    const exerciseType = htmlElement.questionGroups?.[0]?.exerciseType;

    if (!exerciseType) {
      setTypeOption('Section');
      return;
    }

    if (exerciseType === 'Notebook') {
      const notebook = htmlElement.questionGroups[0]?.notebook;

      const notebookModelTypes = {
        Lesson: 'Activity Notebook',
        Section: 'Section',
        Unit: 'Unit Project',
      };

      setTypeOption(notebookModelTypes[notebook.modelType]);
    }
    if (exerciseType === 'SlideShow') setTypeOption('Slide Show');
  }, [data.length, isLoading]);

  // Unit Notebooks / Unit Projects
  const renderUnitNotebookSelects = () => (
    <UnitNotebookSelects
      lessonId={lessonId}
      programId={programId}
      setHasError={setHasError}
      setNotebookId={setNotebookId}
      unitId={unitId}
      notebookId={notebookId}
    />
  );

  // Section Notebooks
  const renderSectionNotebookSelects = () => (
    <SectionNotebookSelects
      lessonId={lessonId}
      programId={programId}
      sectionId={sectionId}
      setHasError={setHasError}
      setNotebookId={setNotebookId}
      setSectionId={setSectionId}
      slideObject={slideObject}
      unitId={unitId}
      notebookId={notebookId}
    />
  );

  // Lesson Notebooks / Activity Notebooks
  const renderLessonNotebookSelects = () => (
    <LessonNotebookSelects
      lessonId={lessonId}
      setHasError={setHasError}
      setNotebookId={setNotebookId}
      unitId={unitId}
      notebookId={notebookId}
    />
  );

  const renderModelSelect = () => (
    <>
      {typeOption === 'Unit Project' && renderUnitNotebookSelects()}
      {typeOption === 'Section' && renderSectionNotebookSelects()}
      {typeOption === 'Activity Notebook' && renderLessonNotebookSelects()}
      {typeOption === 'Slide Show'}
    </>
  );

  const handleTypeChange = e => setTypeOption(e.value);

  const typeSelect = () => (
    <div className={styles.slideQuestionsSelects}>
      <div className={`${styles.modelSelect}`}>
        <label htmlFor="type-select">
          <p>Type:</p>
        </label>
        <Select
          className={`${styles.select} model-select`}
          id="type-select"
          label="type-select"
          value={typeOption}
          onChange={handleTypeChange}
          options={notebookTypeOptions}
        />
      </div>
      {renderModelSelect()}
    </div>
  );

  return (
    <>
      {typeSelect()}
    </>
  );
};

export default QuestionSelectFields;
