import React from 'react';
import * as Routes from '@/modules/routes';
import NotebookCard from '@/components/shared/LessonOverview/Cards/NotebookCard';
import { Lesson, Notebook, Program, SectionType, SlideShow } from '../types';

interface NotebookCardsProps {
  lesson: Lesson;
  program: Program;
  sections: SectionType[];
  stafferId?: number;
}

const slideShowRoute = (lessonId: number, programId: number, slideShow: SlideShow, startingSlideIndex: number) => {
  if (slideShow.use_new_builder) {
    let params = {
      parent_id: lessonId,
      program_id: programId,
      parent_type: 'Lesson',
    };
    if (startingSlideIndex) params['starting_slide'] = startingSlideIndex;

    return Routes.present_mode_shared_slide_show_path(slideShow.id, params);
  }

  return Routes.present_shared_slide_show_path(slideShow.id, {
    program_id: programId,
    lesson_id: lessonId,
  });
};

const getNotebookPath = (section: SectionType, notebook: Notebook, lesson: Lesson, program: Program) => {
  const slideShow = notebook.showWhatYouKnowSlideShow;

  if (slideShow) {
    return slideShowRoute(lesson.id, program.id, slideShow, notebook.showWhatYouKnowSlideIndex);
  }

  if (section.investigation) {
    return Routes.stream_shared_program_lesson_investigations_path(
      program.id,
      lesson.id,
      { expand: notebook.id },
    );
  }

  return Routes.shared_section_path(
    section.id,
    { on_notebook: true, program_id: program.id, anchor: 'notebook' },
  );
};

const NotebookCards = ({ lesson, program, sections, stafferId }: NotebookCardsProps) => (
  <>
    {sections.map(
      section => section.notebooks.map((notebook) => (
        <NotebookCard
          bannerBorderColor="orange"
          fastTrack={notebook.fastTrack}
          key={notebook.id}
          lessonId={lesson.id}
          notebookDescription={notebook.sectionDescription}
          notebookName={notebook.title}
          notebookPath={getNotebookPath(section, notebook, lesson, program)}
          program={program}
          section={section}
          stafferId={stafferId}
          type="Notebook"
        />
      )),
    )}
  </>
);

export default NotebookCards;
