import React from 'react';
import I18n from '@/modules/i18n';
import { Button, Modal, Dialog, DialogTrigger } from 'react-aria-components';
import cn from '@/utils/cn';
import { UnitPlanningHeader } from '@/components/staffer/UnitPlanningDrawer/UnitPlanningHeader';
import UnitTabs from './UnitTabs';
import styles from './UnitPlanningDrawer.module.scss';
import { BUTTON_CLASSES, Resource, useUnitPlanningDrawer } from './utils';

interface UnitPlanningDrawerProps {
  printPdfPath: string;
  resources: Resource[];
}

export const UnitPlanningDrawer = ({ printPdfPath, resources }: UnitPlanningDrawerProps) => {
  const { isOpen, setIsOpen, isExpanded, setIsExpanded } = useUnitPlanningDrawer();

  return (
    <DialogTrigger>
      <Button
        aria-label={I18n.t('open_unit_planning_dialog')}
        aria-expanded={isOpen}
        className={BUTTON_CLASSES}
        onPress={() => setIsOpen(true)}
      >
        {I18n.t('unit_planning')}
        <span className="fa fa-chevron-up" />
      </Button>
      <Modal
        className={cn(styles.drawer, { [styles.fullWidth]: isExpanded })}
        isDismissable
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <Dialog aria-label="Unit Planning drawer" className="tw-h-screen tw-flex tw-flex-col">
          <UnitPlanningHeader
            isExpanded={isExpanded}
            printPdfPath={printPdfPath}
            setIsExpanded={setIsExpanded}
            setIsOpen={setIsOpen}
          />
          <UnitTabs resources={resources} />
          <Button
            aria-label={I18n.t('close_dialog')}
            className={cn(BUTTON_CLASSES, 'tw-absolute -tw-left-[38px] tw-right-auto tw-z-10')}
            slot="close"
            onPress={() => setIsOpen(false)}
          >
            {I18n.t('unit_planning')}
            <span className="fa fa-chevron-down" />
          </Button>
        </Dialog>
      </Modal>
    </DialogTrigger >
  );
};
