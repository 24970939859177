import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { handleRadio } from './RadioGroup';
import {
  ActionButtonsWithError,
  handleCheckbox,
  handleSelect,
  handleSubmit,
  Header,
} from './Util';
import { AssessmentResults } from './StudentAccess/Util';
import {
  RestartLessonReviewGames,
  RestartReadingChecks,
  RestartVideoActivities,
  RestartVocabularyGames,
} from './StudentFeatures/Util';
import GradesForm from './Grades/GradesForm';
import { gradesDefaultSettings } from './Grades/index';

const defaultSettings = {
  ...gradesDefaultSettings,
  lesson_game_retry: false,
  lock_after_turn_in: false,
  student_view_assessment_results_off: false,
  reading_game_retry: true,
  video_activity_retry: false,
  vocab_game_retry: true,
};

interface GradesSettingsFormProps {
  handleCancel: () => void;
  programHasVocabGames: boolean;
  programHasVideoActivity: boolean;
  reviewGameSetting: boolean;
  settings: {
    grade_notebooks_by_lesson: boolean,
    lesson_game_retry: boolean,
    lock_after_turn_in: boolean,
    notebook_possible_points: string | number,
    reading_game_retry: boolean,
    section_possible_points: string | number,
    student_challenge_highlight: string | number,
    student_view_assessment_results_off: boolean,
    trends_highlight: string | number,
    video_activity_retry: boolean,
    vocab_game_retry: boolean,
  };
  stafferPath: string;
  onSubmitSuccess: ({ settings, fieldsChanged }) => void;
};

const GradesSettingsForm = ({
  handleCancel,
  programHasVocabGames,
  programHasVideoActivity,
  reviewGameSetting,
  settings,
  stafferPath,
  onSubmitSuccess,
}: GradesSettingsFormProps) => {

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values, form) => {
    const { dirtyFields } = form.getState();

    handleSubmit({
      values: values,
      setSubmitting: setSubmitting,
      stafferPath: stafferPath,
      onSubmitSuccess: ({ settings }) => onSubmitSuccess({ settings, fieldsChanged: Object.keys(dirtyFields) }),
    })
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...defaultSettings, ...settings }}
      mutators={{
        handleCheckbox: handleCheckbox,
        handleRadio: handleRadio,
        handleSelect: handleSelect
      }}
      render={({ handleSubmit, submitError, form, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Header text="Assessment Access" />

            <AssessmentResults form={form} values={values} />

            <hr />

            <Header text="Allow Multiple Attempts" />

            <RestartLessonReviewGames form={form} values={values} />

            {programHasVocabGames && <RestartVocabularyGames form={form} values={values} />}

            {programHasVideoActivity && <RestartVideoActivities form={form} values={values} />}

            {reviewGameSetting && <RestartReadingChecks form={form} values={values} />}

            <hr />

            <GradesForm form={form} />

            <ActionButtonsWithError
              handleCancel={handleCancel}
              submitError={submitError}
              submitting={submitting}
            />
          </form>
        );
      }}
    />
  );
};

export default GradesSettingsForm;
