import React, { useState, useEffect } from 'react';
import I18n from 'i18n-js';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import TableOfContentsModal from '@/components/shared/NewDashboard/UnitSection/TableOfContentsModal';
import { useModalManager } from '@/components/common/Modal';
import { UnitType } from '@/components/shared/NewDashboard/types';

interface TableOfContentsButtonProps {
  programId: number;
  units: UnitType[];
}

const TableOfContentsButton = ({ programId }: TableOfContentsButtonProps) => {
  const [units, setUnits] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const modalManager = useModalManager();

  useEffect(() => {
    const route = Routes.plato_api_program_units_path(programId);

    axios.get(route)
      .then((response) => {
         setUnits(response.data.data);
         setLoading(false);
      });
  }, [programId]);

  return (
    <>
      <button
        className="tw-bg-inherit tw-border-none tw-cursor-pointer tw-flex tw-text-[#522e91] tw-font-[inherit] tw-text-[14px] tw-items-center"
        type="button"
        onClick={modalManager.open}
        disabled={loading}
      >
        <i className="fa fa-icon fa-list-ul tw-text-xl tw-mr-2" />
        <div className="tw-flex tw-items-center tw-ml-1.5 hover:tw-underline">
           {I18n.t('table_of_contents')}
        </div>
      </button>

        {modalManager.isOpen && (
          <TableOfContentsModal
             isOpen={modalManager.isOpen}
             closeModal={modalManager.close}
             units={units}
          />
        )}
    </>
  );
};

export default TableOfContentsButton;
