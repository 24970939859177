import React from 'react';
import I18n from '@/modules/i18n';
import Tooltip from '@/components/common/Tooltip';
import { HEADER_BUTTON_CLASSES } from '@/components/staffer/UnitPlanningDrawer/utils';
import { Print } from '../../../../images/react/Print';
import cn from '@/utils/cn';

interface PrintPdfButtonProps {
  printPdfPath: string;
}

export const PrintPdfButton = ({ printPdfPath }: PrintPdfButtonProps) => (
  <Tooltip
    content={I18n.t('print_overview')}
    size="medium"
    distance="15"
    interactive={false}
  >
    <a
      aria-label={I18n.t('print_overview')}
      className={cn(HEADER_BUTTON_CLASSES, 'tw-p-1')}
      href={printPdfPath}
      rel="noopener noreferrer"
      role="button"
      target="_blank"
    >
      <Print />
    </a>
  </Tooltip>
);
