import React from 'react';
import pencilIcon from 'images/icons/pencil-orange-outline.png';
import I18n from 'modules/i18n';
import ButtonWithTooltip from '@/components/shared/LessonOverview/Cards/ActionBar/ButtonWithTooltip';
import Card, { ActionBar, CardBody } from './Card';
import {
  BorderColors, Program, SectionType,
} from '../types';
import AssignButton from './AssignButton';
import * as Routes from '../../../../modules/routes';

type NotebookCardProps = {
  bannerBorderColor: BorderColors,
  fastTrack: boolean,
  lessonId: number,
  notebookDescription: string,
  notebookName: string,
  notebookPath: string,
  program: Program,
  section: SectionType,
  stafferId?: number,
  type: string,
};

const NotebookCard = ({
  bannerBorderColor, fastTrack, lessonId, notebookDescription,
  notebookName, notebookPath, program, section, stafferId, type,
}: NotebookCardProps) => {
  const getModelToAssign = (investigationSection) => {
    // Notebooks from stream are assigned all at once by assigning the first notebook of the section
    // Notebooks from non-stream "Text with Notes", "Reading" sections can be assigned individually.
    const model = investigationSection ? section.assignable.value.split(',')[0] : [section.assignable.value];

    // If notebook comes from K-5 Sci program, pre-select "Fast Track" in modal.
    if (fastTrack && program.science) {
      return model.concat('-fast');
    }

    return model;
  };

  const getStreamLink = () => {
    // PDF path for fast track science classes
    if (fastTrack && program.science) {
      return Routes.fast_track_pdf_shared_program_lesson_investigations_path(program.id, lessonId);
    }
    // General PDF path for stream notebooks
    return Routes.lesson_guide_with_extensions_pdf_shared_program_lesson_investigations_path(program.id, lessonId);
  };

  // Returns one of three links based on:
  // 1. Whether or not the notebook comes from the stream/is in an investigation section.
  // 2. Is a fast track notebook in a science program. (AKA K-5 Sci)
  const getLink = (investigationSection) => {
    const link = investigationSection ? getStreamLink()
      : Routes.pdf_shared_program_lesson_path(program.id, lessonId, { locale: I18n.locale });

    return link;
  };

  const renderPrintButton = (investigationSection) => {
    // Investigation Notebook tab of MS Sci classes don't have the print pdf option.
    // If notebook is from stream, if the notebook isn't fast track, and if the program is science.
    if (investigationSection && !fastTrack && program.science) {
      return <></>;
    }

    return (
      <ButtonWithTooltip
        ariaLabel="Print notebook"
        link={getLink(investigationSection)}
        openInNewWindow
        tooltipContent={I18n.t('print_text')}
        iconClassName="fa fa-print"
      />
    );
  };

  const renderActionBar = () => {
    const investigationSection = section.investigation;

    const initialValues = {
      modelType: investigationSection ? 'investigation' : 'sectionNotebook',
      modelsToAssign: getModelToAssign(investigationSection),
    };

    return (
      <ActionBar>
        <AssignButton
          initialValues={initialValues}
          isAssigned={section.isAssigned}
          lessonId={lessonId}
          program={program}
          stafferId={stafferId}
        />

        {renderPrintButton(investigationSection)}
      </ActionBar>
    );
  };

  return (
    <Card bannerBorderColor={bannerBorderColor} label="notebook" type={type}>
      <CardBody
        ariaLabel={notebookName}
        iconSrc={pencilIcon}
        linkHref={notebookPath}
        linkLabel={notebookName}
      >
        {notebookDescription}
      </CardBody>
      {stafferId && renderActionBar()}
    </Card>
  );
};

export default NotebookCard;
