import React, { useState } from 'react';
import { SubmitModal, useModalManager } from '@/components/common/Modal';
import { plato_api_classroom_lesson_path, plato_api_classroom_lessons_path } from '@/modules/routes';
import SwitchGradeModeConfirmationText from '@/components/staffer/NotebookGradeByMenu/SwitchGradeModeConfirmationText';
import { GradeByType } from '@/components/staffer/NotebookGradeByMenu/types';

const useGradeByMethods = ({
  afterSubmitCallback,
  classroomId,
  classroomLessonId,
  gradingByLesson,
  lessonId,
  lessonTitle,
}) => {
  const initialGradingByLesson = classroomLessonId ? gradingByLesson : true;
  const [gradeByLesson, setGradeByLesson] = useState(initialGradingByLesson);
  const [reloading, setReloading] = useState(false);
  const modalManager = useModalManager();

  const openGradeByLessonModal = () => {
    if (initialGradingByLesson) return;

    setGradeByLesson(true);
    modalManager.open();
  };

  const openGradeBySectionModal = () => {
    if (!initialGradingByLesson) return;

    setGradeByLesson(false);
    modalManager.open();
  };

  const afterSubmit = (response: any) => {
    if (!afterSubmitCallback) {
      setReloading(true);
      window.location.reload();
    } else {
      afterSubmitCallback(response);
    }
  };

  const createData = {
    'classroom_lesson[classroom_id]': classroomId,
    'classroom_lesson[grading_notebook_by_lesson]': gradeByLesson,
    'classroom_lesson[grading_notebook_by]': gradeByLesson ? GradeByType.Lesson : GradeByType.Section,
    'classroom_lesson[lesson_id]': lessonId,
  };

  const updateData = {
    'classroom_lesson[grading_notebook_by_lesson]': gradeByLesson,
    'classroom_lesson[grading_notebook_by]': gradeByLesson ? GradeByType.Lesson : GradeByType.Section,
  };

  const getPath = () => {
    if (classroomLessonId) return plato_api_classroom_lesson_path(classroomLessonId);

    return plato_api_classroom_lessons_path();
  };

  const renderModal = () => (
    <SubmitModal
      headerText={gradeByLesson ? 'Grade by Lesson' : 'Grade by Section'}
      submitPath={getPath()}
      method={classroomLessonId ? 'patch' : 'post'}
      closeModal={modalManager.close}
      isOpen={modalManager.isOpen}
      afterSubmit={afterSubmit}
      additionalData={classroomLessonId ? updateData : createData}
      closeAfterSubmit={false}
      submitting={reloading}
    >
      <SwitchGradeModeConfirmationText
        gradingBy={gradeByLesson ? GradeByType.Lesson : GradeByType.Section}
        lessonTitle={lessonTitle}
      />
    </SubmitModal>
  );

  return { initialGradingByLesson, openGradeByLessonModal, openGradeBySectionModal, renderModal };
};

export default useGradeByMethods;
