import cn from '@/utils/cn';
import { useEffect, useState } from 'react';

export const BUTTON_CLASSES = cn(
  'tw-flex tw-items-center tw-justify-between tw-gap-2',
  'text-sideways-lr shadow-soft',
  'tw-fixed tw-top-[200px] tw-right-0',
  'tw-text-white tw-text-lg tw-font-semibold tw-h-max tw-w-[38px] tw-py-4',
  'tw-cursor-pointer tw-z-20 tw-transition-all',
  'tw-border-none tw-rounded-tl-xl tw-rounded-bl-xl',
  'tw-bg-[#E68123] focus:tw-text-[#E68123] focus:tw-bg-white focus:tw-border-[#E68123]',
  'hover:tw-text-[#E68123] hover:tw-bg-white hover:tw-border-[#E68123]',
);

export const HEADER_BUTTON_CLASSES = cn(
  ' tw-w-7 tw-h-7 tw-box-border tw-rounded-full tw-shadow-none tw-outline-none tw-border-none',
  'tw-fill-tci-purple tw-p-2 tw-inline-flex tw-items-center tw-justify-center tw-cursor-pointer',
  'tw-bg-light-grey hover:tw-bg-tci-purple hover:tw-fill-light-grey tw-transition-all',
  'focus:tw-text-light-grey hover:tw-text-light-grey',
  'focus:tw-bg-tci-purple focus:tw-fill-light-grey focus:tw-outline-none',
);

export type Resource = {
  id: number;
  html: string;
  title: string;
};

export const useUnitPlanningDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isOpen) return;

    setIsExpanded(false);
  }, [isOpen]);

  return {
    isExpanded,
    isOpen,
    setIsExpanded,
    setIsOpen,
  };
};
