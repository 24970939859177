import React from 'react';
import { useModalManager } from 'components/common/Modal';
import Tooltip from 'components/common/Tooltip';
import AsyncAssignmentModal from 'components/staffer/AssignmentModal/AsyncAssignmentModal';
import I18n from 'modules/i18n';
import * as Routes from 'modules/routes';
import styles from './index.module.scss';

type InitialAssignableType = {
  modelType: string,
  modelsToAssign: string | string[] | { label: string, value: string }[],
};

interface AssignButtonProps {
  handlePostSave?: Function,
  initialValues: InitialAssignableType,
  isAssigned: boolean,
  lessonId: number,
  program: { id: number },
  stafferId: number,
}

const AssignButton = ({
  handlePostSave = () => {},
  initialValues,
  isAssigned,
  lessonId,
  program,
  stafferId
}: AssignButtonProps) => {
  const modalManager = useModalManager();

  const tooltipContent = () => {
    if (!isAssigned) return I18n.t('create_assignment');

    const assignmentsLink = Routes.teacher_program_assignments_path(program.id, { lesson_id: lessonId });

    return (
      <div className={styles.assignmentTooltip}>
        <div className={styles.tooltipHeader}>{I18n.t('create_assignment_header')}</div>
        <hr />
        <div>
          {I18n.t('assignment_already_created')}
          {' '}
          <a href={assignmentsLink}>{I18n.t('view_details')}</a>
        </div>
      </div>
    );
  };

  return (
    <>
      <Tooltip
        content={tooltipContent()}
        size="medium"
        distance="15"
        hideOnClick
        interactive={isAssigned}
      >
        <button
          type="button"
          aria-label={I18n.t('create_assignment')}
          className={styles.iconLink}
          onClick={modalManager.open}
        >
          <i
            className={`fa fa-calendar${isAssigned ? `-check-o ${styles.assigned}` : ''} ${styles.icon}`}
            aria-hidden="true"
          />
          <span className="sr-only">{I18n.t('create_assignment')}</span>
        </button>
      </Tooltip>

      <AsyncAssignmentModal
        closeModal={modalManager.close}
        handlePostSave={handlePostSave}
        initialValues={initialValues}
        initialLessonId={lessonId}
        modalIsOpen={modalManager.isOpen}
        program={program}
        stafferId={stafferId}
      />
    </>
  );
};

export default AssignButton;
