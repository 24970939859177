import { useEffect, useState } from 'react';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import { getSectionsByKind } from '@/components/shared/LessonOverview/utils';
import { groupBy } from '@/modules/TCIUtils';
import IDivingDeeper from '@/types/api/DiveDeeper';

type UseLessonOverviewProps = {
  programId: number,
  lessonId: number,
  locale: string,
};

const diveDeeperFormat = (lesson, sections) => {
  const sectionsByKind = getSectionsByKind(sections);

  const readingTypes = Object.keys(sectionsByKind).filter(kind => (
    !['default', 'unit projects', 'key science concept'].includes(kind)
  ));

  const { referenceMaterialAssignments } = lesson;
  const groupedAssignments = groupBy(referenceMaterialAssignments, 'type');

  const diveDeeper = { referenceMaterials: [], readings: [] };

  Object.keys(groupedAssignments)
    .map(assignmentType => groupedAssignments[assignmentType]
      .forEach(assignment => diveDeeper.referenceMaterials.push(assignment)));
  readingTypes.forEach(type => sectionsByKind[type].forEach(section => diveDeeper.readings.push(section)));

  return diveDeeper;
};

const useLessonOverview = ({ programId, lessonId, locale } : UseLessonOverviewProps) => {
  const [lesson, setLesson] = useState(null);
  const [program, setProgram] = useState(null);
  const [sections, setSections] = useState([]);
  const [slideShows, setSlideShows] = useState(null);
  const [diveDeeper, setDiveDeeper] = useState<IDivingDeeper>({ referenceMaterials: [], readings: [] });
  const [isLoading, setIsLoading] = useState(false);

  const lessonOverviewPath = Routes.plato_api_program_lesson_overview_index_path(
    programId, lessonId, { locale },
  );

  useEffect(() => {
    axios.get(lessonOverviewPath)
      .then((response) => {
        setLesson(response.data.lesson);
        setProgram(response.data.program);
        setSections(response.data.sections);
        setSlideShows(response.data.slideShows);

        const diveDeeperArray = diveDeeperFormat(
          response.data.lesson,
          response.data.sections,
        );
        setDiveDeeper(diveDeeperArray);
      }).finally(() => setIsLoading(false));
  }, []);

  return { lesson, program, sections, slideShows, diveDeeper, isLoading };
};
export default useLessonOverview;
