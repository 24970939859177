import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import I18n from 'i18n';
import * as Routes from 'routes';
import { formatLocalTimestamp } from 'common/Utils';
import { decode } from 'html-entities';
import { ToggleMenu, toggleOptionShape } from './ToggleMenu';
import Pager, { pagerDataShape } from '../../../common/Pager';
import GradingToggleGroups from './GradingToggleGroups';
import ScoreEdit from './ScoreEdit';
import PostGradeButton from './PostGradeButton';
import UnitProjectScore from './UnitProjectScore';
import ActivityScore from './ActivityScore';
import PostClassGradesButton from './PostClassGradesButton';
import SubmitAssessmentButton from '../../../staffer/assessments/SubmitAssessmentButton';
import GameResetButton from './GameResetButton';
import styles from './Navigation.module.scss';
import BulkGradingWrapper from './BulkGrading/index';
import GradingDropdownGroups from './GradingDropdownGroups';

const scoreDataShape = PropTypes.shape({
  answerID: PropTypes.number,
  canEdit: PropTypes.bool,
  containerClass: PropTypes.string,
  hasBeenGraded: PropTypes.bool,
  maxScore: PropTypes.number,
  maxUnitProjectScore: PropTypes.number,
  promptOnly: PropTypes.bool,
  questionAnswered: PropTypes.bool,
  questionAutoGraded: PropTypes.bool,
  questionId: PropTypes.number,
  score: PropTypes.number,
  tooltip: PropTypes.string,
  updateURL: PropTypes.string,
  updatedAt: PropTypes.string
});

export default class Navigation extends Component {
  static propTypes = {
    activityType: PropTypes.string,
    answerableQuestionsCount: PropTypes.number.isRequired,
    assessmentGraded: PropTypes.bool,
    assessmentInProgress: PropTypes.bool,
    assessmentPastDue: PropTypes.bool,
    assessmentTitle: PropTypes.string,
    classScoresSubmitted: PropTypes.bool,
    classroomName: PropTypes.string,
    classroomOptionsData: PropTypes.arrayOf(toggleOptionShape).isRequired,
    classroomPeriod: PropTypes.string,
    clearWorkPath: PropTypes.string,
    currentClassroomID: PropTypes.string.isRequired,
    currentQuestionID: PropTypes.number,
    currentStudentID: PropTypes.number,
    displayAutoGradedToggle: PropTypes.bool.isRequired,
    exerciseType: PropTypes.string.isRequired,
    exerciseResult: PropTypes.shape({id: PropTypes.string, score: PropTypes.string}),
    gameId: PropTypes.string,
    gradeMode: PropTypes.string.isRequired,
    gradingByLesson: PropTypes.bool,
    gradingGame: PropTypes.bool,
    gradingSlideshow: PropTypes.bool,
    lessonId: PropTypes.number,
    lessonTitle: PropTypes.string.isRequired,
    locale: PropTypes.string,
    notebookSectionResults: PropTypes.arrayOf(PropTypes.shape()),
    numQuestionsAnswered: PropTypes.number.isRequired,
    numTotalQuestions: PropTypes.number.isRequired,
    pagingData: pagerDataShape.isRequired,
    postGradePath: PropTypes.string,
    postScoresPath: PropTypes.string,
    programId: PropTypes.number,
    questionOptionsData: PropTypes.arrayOf(toggleOptionShape),
    questionViewPath: PropTypes.string,
    questionsGradingStatus: PropTypes.arrayOf(PropTypes.shape({
      numAnswersGraded: PropTypes.number.isRequired,
      questionID: PropTypes.number.isRequired
    })),
    regradeClassPath: PropTypes.string,
    regradeStudentPath: PropTypes.string,
    scoresData: PropTypes.oneOfType([
      scoreDataShape,
      PropTypes.arrayOf(scoreDataShape)
    ]).isRequired,
    sectionMaxScores: PropTypes.objectOf(PropTypes.number),
    slideGroupsCount: PropTypes.number,
    slideGroupMaxScores: PropTypes.objectOf(PropTypes.number),
    slideGroupExerciseResults: PropTypes.arrayOf(PropTypes.shape()),
    slideshowMaxScore: PropTypes.number,
    slideshowTitle: PropTypes.string,
    studentName: PropTypes.string.isRequired,
    studentOptionsData: PropTypes.arrayOf(toggleOptionShape),
    studentSubmissionInfo: PropTypes.shape({
      completionStatus: PropTypes.shape({
        color: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      }).isRequired,
      submissionTime: PropTypes.string
    }),
    studentViewPath: PropTypes.string,
    studentsCount: PropTypes.number,
    submitAssessmentPath: PropTypes.string,
    unitId: PropTypes.number,
    unitTitle: PropTypes.string,
    updatedGradesTable: PropTypes.bool,
    userType: PropTypes.string,
  };

  static defaultProps = {
    activityType: '',
    assessmentGraded: false,
    assessmentInProgress: false,
    assessmentPastDue: false,
    assessmentTitle: null,
    classScoresSubmitted: false,
    classroomName: null,
    classroomPeriod: null,
    clearWorkPath: null,
    currentQuestionID: null,
    currentStudentID: null,
    exerciseResult: null,
    gameId: '',
    gradingByLesson: false,
    gradingGame: false,
    gradingSlideshow: false,
    lessonId: null,
    locale: 'en',
    notebookSectionResults: null,
    postGradePath: null,
    postScoresPath: null,
    programId: null,
    questionOptionsData: [],
    questionViewPath: null,
    questionsGradingStatus: [],
    regradeClassPath: null,
    regradeStudentPath: null,
    sectionMaxScores: {},
    slideGroupsCount: null,
    slideGroupExerciseResults: null,
    slideGroupMaxScores: {},
    slideshowMaxScore: null,
    slideshowTitle: '',
    studentOptionsData: [],
    studentSubmissionInfo: null,
    studentViewPath: null,
    studentsCount: null,
    submitAssessmentPath: null,
    unitId: null,
    unitTitle: '',
    updatedGradesTable: false,
    userType: 'teacher',
  };

  constructor(props) {
    super(props);
    this._handleScoreChange = this._handleScoreChange.bind(this);
    this._updateNotebookScore = this._updateNotebookScore.bind(this);
    this._updateSlideGroupScore = this._updateSlideGroupScore.bind(this);

    const scoresMap = {};

    let totalExerciseScore = 0;

    if (this.props.gradingGame) {
      this.maxScore = this.props.scoresData.maxScore;

      if (this.props.exerciseResult && this.props.exerciseResult.score) {
        totalExerciseScore = this.props.exerciseResult.score;
      }
    }

    if (this.props.scoresData instanceof Array && this.props.scoresData.length !== 0) {
      this.props.scoresData.forEach((scoreData) => {
        scoresMap[scoreData.answerID] = scoreData.score;
      });

      this.maxScore = this.props.scoresData.reduce((total, data) => total + data.maxScore, 0);
      totalExerciseScore = this.props.scoresData.reduce((total, data) => {
        let studentScore = 0;
        if (data.score) {
          studentScore = typeof (data.score) === 'string' ? parseFloat(data.score, 10) : data.score;
        }

        return total + studentScore
      }, 0);
    }

    const gradingProgressMap = {};
    this.props.questionsGradingStatus.forEach((statusData) => {
      gradingProgressMap[statusData.questionID] = statusData.numAnswersGraded;
    });

    const sectionResults = props.notebookSectionResults || [];
    const notebookScores = sectionResults.reduce((scores, result) => {
      const newScores = {...scores};
      newScores[result.exercise_id] = parseFloat(result.score);

      return newScores;
    }, {});

    const slideshowResults = props.slideGroupExerciseResults || [];
    const slideGroupScores = slideshowResults.reduce((scores, result) => {
      const newScores = {...scores};
      newScores[result.exercise_id] = parseFloat(result.score);

      return newScores;
    }, {});

    const slideGroupQuestionsScoresMap = {};

    if (this.props.gradingSlideshow) {
      this.props.scoresData.forEach((scoreData) => {
        if (!slideGroupQuestionsScoresMap[scoreData.exerciseId]) slideGroupQuestionsScoresMap[scoreData.exerciseId] = [];

        slideGroupQuestionsScoresMap[scoreData.exerciseId].push({
          answerId: scoreData.answerID,
          questionId: scoreData.questionId,
          score: scoreData.score,
        });
      });
    }

    this.state = {
      gradingProgressMap,
      notebookScores,
      scoresData: this.props.scoresData,
      scoresMap,
      slideGroupScores,
      totalExerciseScore,
      slideGroupQuestionsScoresMap
    };
  }

  _renderTitle() {
    return (
      <div className={styles.titleContainer}>
        {!this.props.unitId && (
          <div className="tw-flex tw-justify-between tw-items-center tw-h-6">
            <span>
              <strong>{I18n.t('lesson')}:</strong> {this.props.lessonTitle}
            </span>
            {this.props.updatedGradesTable && this._renderPager()}
          </div>
        )}

        {this.props.unitId && (
          <div className="tw-flex tw-justify-between tw-items-center tw-h-6">
            <span>
              <strong>{I18n.t('unit')}:</strong> {this.props.unitTitle}
            </span>
            {this.props.updatedGradesTable && this._renderPager()}
          </div>
        )}
        {this._renderSlideshowTitle()}
        {this._renderAssessmentTitle()}
      </div>
    );
  }

  _renderSlideshowTitle() {
    if (this.props.gradingSlideshow) {
      const slideGroupCount = this.props.slideGroupsCount > 1 ?
        ` (${I18n.t('slide_groups')}: ${this.props.slideGroupsCount})` : '';

      return (
        <span>
          <b>{I18n.t('slideshow')}:</b> {this.props.slideshowTitle + slideGroupCount}
        </span>
      );
    }

    return null;
  }

  _renderAssessmentTitle() {
    if (this.props.assessmentTitle) {
      return (
        <span>
          <b>Test Title:</b> {decode(this.props.assessmentTitle)} (Questions: {this.props.answerableQuestionsCount})
        </span>
      );
    }

    return null;
  }

  // Moved into GradingDropdownGroups component.
  // Can delete after removing "updated_grades_table" flag.
  _renderClassroomToggle() {
    if (this.props.userType === 'student') return null;

    return (
      <ToggleMenu
        id="gradebook-classroom-toggle"
        label="Classroom"
        value={this.props.currentClassroomID}
        width="200px"
        optionsData={this.props.classroomOptionsData}
      />
    );
  }

  _renderStudentToggle() {
    if (this.props.currentStudentID === null) return null;

    return (
      <ToggleMenu
        id="gradebook-student-toggle"
        label="Student"
        value={this.props.currentStudentID}
        width="200px"
        optionsData={this.props.studentOptionsData}
      />
    );
  }

  _renderPager() {
    if (this.props.userType === 'student') return null;
    const modelName = this.props.currentStudentID ? 'student' : 'question';

    return (
      <Pager
        modelName={modelName}
        pagingData={this.props.pagingData}
        updatedGradesTable={this.props.updatedGradesTable}
      >
        {modelName === 'student' ? this._renderStudentToggle() : this._renderQuestionToggle()}
      </Pager>
    );
  }

  _renderQuestionToggle() {
    if (this.props.currentQuestionID === null) return null;

    return (
      <ToggleMenu
        id="gradebook-question-toggle"
        label="Question"
        value={this.props.currentQuestionID}
        width="330px"
        optionsData={this.props.questionOptionsData}
      />
    );
  }

  _renderCompletionStatus() {
    const {color, label} = this.props.studentSubmissionInfo.completionStatus;
    const className = `fa fa-circle ml5 ${styles[color]}`;

    return <div>Status: <i className={className} aria-hidden /> {label}</div>;
  }

  _renderCompletionTimestamp() {
    if (!this.props.studentSubmissionInfo.completionTime) return null;

    const label = formatLocalTimestamp(this.props.studentSubmissionInfo.completionTime, false);
    return <div>Completed at: {label}</div>;
  }

  _renderSubmittedStatus() {
    const label = formatLocalTimestamp(this.props.studentSubmissionInfo.submissionTime, false) || 'Not turned in';
    return <div>Turned in: {label}</div>;
  }

  _renderTimeStamp() {
    if (this.props.gradingGame || this.props.exerciseType === 'ReadingGame') {
      return this._renderCompletionTimestamp();
    }

    return this._renderSubmittedStatus();
  }

  _assessmentSubmitted() {
    return this.props.studentSubmissionInfo && this.props.studentSubmissionInfo.submissionTime;
  }

  _renderTotalScore() {
    if (!this.props.assessmentGraded && this.props.userType === 'student') {
      return <div>Total score: Grading in Progress / {this.maxScore}</div>;
    }

    if (!this._assessmentSubmitted() && !this.props.gradingGame) {
      return <div>Total score: N/A</div>;
    }

    let label = `${parseFloat(this.state.totalExerciseScore)} / ${this.maxScore}`;
    if (this.state.numAnswersUngraded > 0) label += ` (${this.state.numAnswersUngraded} ungraded)`;

    return <div>Total score: {label}</div>;
  }

  _renderPostGradeButton() {
    if (this.props.userType === 'student') return null;

    return (
      <PostGradeButton
        alreadyGraded={this.props.assessmentGraded}
        clearWorkPath={this.props.clearWorkPath}
        exerciseResult={this.props.exerciseResult}
        exerciseType={this.props.exerciseType}
        locale={this.props.locale}
        postGradePath={this.props.postGradePath}
        programId={this.props.programId}
        readyToSubmit={this._assessmentSubmitted() && this.state.numAnswersUngraded === 0}
        regradeStudentPath={this.props.regradeStudentPath}
      />
    );
  }

  _renderGameResetButton() {
    if (this.props.userType === 'student' || !this.props.exerciseResult) return null;

    return (
      <GameResetButton
        exerciseResultId={this.props.exerciseResult.id}
        gameId={this.props.gameId}
        gameType={this.props.activityType}
      />
    );
  }

  _renderCTA() {
    if (this.props.gradingGame || this.props.exerciseType === 'ReadingGame') {
      return this._renderGameResetButton();
    }

    return this._renderPostGradeButton();
  }

  _renderStudentGradingProgress() {
    if (!this.props.studentSubmissionInfo || this.props.exerciseType === 'Notebook' || this.props.gradingSlideshow || this.props.gradingBiography) {
      return null;
    }

    return (
      <div className={styles.gradingProgress}>
        {this._renderCompletionStatus()}
        {this._renderTimeStamp()}
        {this._renderTotalScore()}
        {this._renderCTA()}
      </div>
    );
  }

  _renderPostClassGradesButton(allQuestionsGraded) {
    const params = new URLSearchParams(window.location.search);

    return (
      <PostClassGradesButton
        allQuestionsGraded={allQuestionsGraded}
        assessmentId={parseInt(params.get('assessment_id'), 10)}
        classroomId={parseInt(this.props.currentClassroomID, 10)}
        classroomName={this.props.classroomName}
        classroomPeriod={this.props.classroomPeriod}
        classScoresSubmitted={this.props.classScoresSubmitted}
        lessonId={this.props.lessonId || parseInt(params.get('lesson_id'), 10)}
        postScoresPath={this.props.postScoresPath}
        programId={this.props.programId || parseInt(params.get('program_id'), 10)}
        regradeStudentPath={this.props.regradeClassPath}
        updatedGradesTable={this.props.updatedGradesTable}
      />
    );
  }

  _renderQuestionGradingProgress() {
    if (this.props.gradeMode === 'student' || this.props.exerciseType === 'Notebook') return null;

    const questionsData = Object.values(this.state.gradingProgressMap);

    const completelyGraded = questionsData.filter(numAnswersGraded => numAnswersGraded === this.props.studentsCount);

    const partiallyGraded = questionsData.filter(numAnswersGraded => numAnswersGraded !== this.props.studentsCount && numAnswersGraded !== 0);

    const allQuestionsGraded = questionsData.length === completelyGraded.length;

    return (
      <div className={styles.gradingProgress}>
        <div>Total questions: {questionsData.length}</div>
        <div>Completely scored: {completelyGraded.length}</div>
        <div>Partially scored: {partiallyGraded.length}</div>
        {!this.props.gradingSlideshow && this._renderPostClassGradesButton(allQuestionsGraded)}
      </div>
    );
  }

  _handleScoreChange(answerID, slideGroupId, newScore) {
    if (this.gradeMode === 'student' && !this._assessmentSubmitted()) return;

    if (slideGroupId && this.props.gradingSlideshow) {
      // use slide group id to find question score to update
      const slideGroupScoresMap = Object.assign({}, this.state.slideGroupQuestionsScoresMap);

      // Find the correct questionAnswer to update its score.
      const qAnswer = slideGroupScoresMap[slideGroupId].find((questionAnswer) => {
        // If the question has not been answered, aka the questionAnswer doesn't have an answerId
        // find the correct questionAnswer via its questionId.
        if (!questionAnswer.answerId) return questionAnswer.questionId === answerID;

        return (questionAnswer.answerId === answerID);
      });

      if (qAnswer) {
        qAnswer.score = newScore;
      }
    }

    const scoresMap = Object.assign({}, this.state.scoresMap);
    scoresMap[answerID] = newScore;

    const allAnswerScores = Object.values(scoresMap).map(score => parseFloat(score));
    const gradedAnswerScores = allAnswerScores.filter(score => !isNaN(score));
    const totalExerciseScore = gradedAnswerScores.length && gradedAnswerScores.reduce((total, score) => total + score);

    const numAnswersUngraded = allAnswerScores.length - gradedAnswerScores.length;

    const gradingProgressMap = Object.assign({}, this.state.gradingProgressMap);
    gradingProgressMap[this.props.currentQuestionID] = gradedAnswerScores.length;

    this.setState({
      gradingProgressMap,
      numAnswersUngraded,
      scoresMap,
      totalExerciseScore,
    });
  }

  _updateNotebookScore(notebookId, _, score) {
    this.setState((prevState) => {
      const newNotebookScores = {...prevState.notebookScores};
      newNotebookScores[notebookId] = score;

      const hasBeenGraded = Object.values(newNotebookScores).some(newScore => newScore !== null && newScore !== '');
      const newScoresData = {...prevState.scoresData, hasBeenGraded: hasBeenGraded};

      return {notebookScores: newNotebookScores, scoresData: newScoresData};
    });
  }

  _updateSlideGroupScore(_, slideGroupId, score) {
    this.setState((prevState) => {
      const newSlideGroupScores = {...prevState.slideGroupScores};
      newSlideGroupScores[slideGroupId] = score;

      return {slideGroupScores: newSlideGroupScores};
    });
  }

  _renderScoreField(data) {
    if (!this.props.assessmentGraded && this.props.userType === 'student') {
      data.score = null;
      data.tooltip = 'Grading in progress';
      data.updatedAt = null;
    }

    let score = data.score;
    if ((this.props.exerciseType === 'Notebook' && this.props.gradeMode === 'student' && !this.props.gradingByLesson && !this.props.activityType) ||
      this.props.unitId) {
      score = Object.keys(this.state.notebookScores).reduce((totalScore, notebookId) => (
        totalScore + (parseFloat(this.state.notebookScores[notebookId]) || 0)
      ), 0);
    }

    if (this.props.unitId) {
      return (
        <UnitProjectScore
          score={score}
          maxScore={this.state.scoresData.maxScore}
          hasBeenGraded={this.state.scoresData.hasBeenGraded}
        />
      );
    }

    return (
      <ScoreEdit
        activityType={this.props.activityType}
        answerID={data.answerID}
        score={score}
        maxScore={data.maxScore}
        updateURL={data.updateURL}
        lastUpdated={data.updatedAt}
        canEdit={data.canEdit && this.props.userType === 'teacher'}
        tooltip={data.tooltip}
        exerciseType={this.props.exerciseType}
        updateScoresMap={this._handleScoreChange}
        slideGroupId={this.props.gradingSlideshow ? data.exerciseId : null}
        questionId={data.questionId}
      />
    );
  }

  totalMaxScore() {
    return (
      this.state.scoresData.reduce((partialSum, score) => partialSum + score.maxScore, 0)
    );
  }

  totalScore() {
    return (
      Object.values(this.state.scoresMap).reduce((partialSum, score) => partialSum + parseFloat(score || 0), 0)
    );
  }

  slideGroupScore(slideGroupId) {
    // Confirm that the slide group question has a score.
    const hasScore = !!this.state.slideGroupQuestionsScoresMap[slideGroupId] &&
      this.state.slideGroupQuestionsScoresMap[slideGroupId].some(question => (!!question.score));

    if (!hasScore) return '';

    return (
      this.state.slideGroupQuestionsScoresMap[slideGroupId].filter(question => (!!question.score))
        .reduce((partialSum, question) => (partialSum + parseFloat(question.score)), 0)
    );
  }

  // Render either the score fields for each answer card or the overall score score field for the student's notebook
  _renderAllScoreFields() {
    if (this.state.scoresData instanceof Array) {
      return (
        <>
          {(this.props.activityType === 'video_activity' || this.props.gradingBiography ||
            (this.props.gradingSlideshow && this.props.gradeMode === 'student')) && (
            <ActivityScore
              hasBeenGraded={Object.values(this.state.scoresMap).some(score => score)}
              maxScore={this.totalMaxScore()}
              score={this.state.totalExerciseScore}
              ungradedCount={this.state.numAnswersUngraded}
            />
          )}
          {this.state.scoresData.map((data) => {
            const container = document.querySelector(data.containerClass);
            if (!container) return null;

            const field = this._renderScoreField(data);
            return ReactDOM.createPortal(field, container);
          })}
        </>
      );
    }

    return this._renderScoreField(this.state.scoresData);
  }

  _renderSectionScoreFields() {
    const scoreWrappers = Array.from(document.querySelectorAll('.section-score-wrapper'));

    return scoreWrappers.map((wrapper) => {
      const notebookId = parseFloat(wrapper.getAttribute('data-notebook-id'));
      const sectionId = parseFloat(wrapper.getAttribute('data-section-id'));

      const notebookResult = this.props.notebookSectionResults.find(result => result.exercise_id === notebookId);
      let score = notebookResult ? parseFloat(notebookResult.score) : null;
      if (isNaN(score)) score = null;
      const lastUpdated = notebookResult ? notebookResult.last_updated : null;

      const updateUrl = Routes.teacher_notebook_score_path(
        this.props.currentStudentID,
        {
          classroom_id: this.props.currentClassroomID,
          exercise_id: notebookId,
          format: 'json',
          lesson_id: this.props.lessonId,
          program_id: this.props.programId,
          unit_id: this.props.unitId,
        }
      );

      return ReactDOM.createPortal(
        <div className="section-score-container">
          <ScoreEdit
            canEdit={!this.props.gradingByLesson || this.props.unitId || this.props.activityType}
            maxScore={this.props.sectionMaxScores[sectionId] || this.props.scoresData.maxUnitProjectScore}
            exerciseType={this.props.unitId || this.props.activityType ? 'Notebook' : 'Section'}
            score={score}
            updateURL={updateUrl}
            updateScoresMap={this._updateNotebookScore}
            lastUpdated={lastUpdated}
            notebookId={notebookId}
            sectionId={sectionId}
            unitId={this.props.unitId}
            tooltip={this.props.gradingByLesson && !this.props.unitId && !this.props.activityType ? 'You are grading by lesson' : null}
          />
        </div>,
        wrapper
      );
    });
  }

  _renderSlideGroupScoreFields() {
    const scoreWrappers = Array.from(document.querySelectorAll('.slideshow-score-wrapper'));
    const exerciseType = this.props.exerciseType;

    return scoreWrappers.map((wrapper) => {
      const slideGroupId = parseFloat(wrapper.getAttribute('data-slide-group-id'));

      const slideGroupResult = this.state.slideGroupScores[slideGroupId];
      const lastUpdated = slideGroupResult ? slideGroupResult.last_updated : null;

      let score = this.slideGroupScore(slideGroupId);
      if (isNaN(score)) score = null;

      const updateUrl = Routes.teacher_notebook_score_path(
        this.props.currentStudentID,
        {
          classroom_id: this.props.currentClassroomID,
          exercise_id: slideGroupId,
          format: 'json',
          lesson_id: this.props.lessonId,
          program_id: this.props.programId,
          unit_id: this.props.unitId,
        }
      );

      return ReactDOM.createPortal(
        <div className="slide-group-score-container">
          <ScoreEdit
            canEdit={false}
            maxScore={this.props.slideGroupMaxScores[slideGroupId]}
            exerciseType={exerciseType}
            score={score}
            updateURL={updateUrl}
            updateScoresMap={this._updateSlideGroupScore}
            lastUpdated={lastUpdated}
            notebookId={slideGroupId}
            unitId={this.props.unitId}
          />
        </div>,
        wrapper
      );
    });
  }

  _renderAssessmentInProgressWarning() {
    const gradingStudentAssessment = this.props.gradeMode === 'student' && this.props.exerciseType === 'Assessment';

    if (gradingStudentAssessment && this.props.assessmentInProgress) {
      if (this.props.assessmentPastDue) {
        return (
          <SubmitAssessmentButton
            studentName={this.props.studentName}
            submitPath={this.props.submitAssessmentPath}
            className="mt15"
          />
        );
      }

      return (
        <div className={styles.inProgressWarning}>
          <i className="fa fa-exclamation-circle mr5" aria-hidden />
          Assessment in progress. Please return to grade when this student has completed the assessment.
        </div>
      );
    }

    return null;
  }

  _renderStudentProgress() {
    if ((this.props.exerciseType !== 'Notebook' && !this.props.gradingSlideshow && !this.props.gradingBiography) ||
      this.props.gradeMode === 'question') {
      return null;
    }

    return (
      <div className="mt10">
        {this.props.numQuestionsAnswered}/{this.props.numTotalQuestions} questions answered
      </div>
    );
  }

  bulkGradingButton() {
    if (this.props.gradeMode === 'question' && this.props.scoresData.length > 0 &&
      !this.props.scoresData[0]?.questionAutoGraded && !this.props.scoresData[0]?.promptOnly) {
      return (
        <BulkGradingWrapper
          scoresData={this.state.scoresData}
        />
      );
    }
  }

  extraNavigation() {
    return (
      <>
        <div className={styles.bottomRow}>
          <div>
            {this.props.updatedGradesTable ? (
              <GradingDropdownGroups
                activityType={this.props.activityType}
                currentClassroomID={this.props.currentClassroomID}
                classroomOptionsData={this.props.classroomOptionsData}
                studentViewPath={this.props.studentViewPath}
                questionViewPath={this.props.questionViewPath}
                modelType={this.props.currentStudentID ? 'student' : 'question'}
                displayAutoGradedToggle={this.props.displayAutoGradedToggle}
                unitId={this.props.unitId}
                exerciseType={this.props.exerciseType}
              />
            ) : (
              <GradingToggleGroups
                activityType={this.props.activityType}
                studentViewPath={this.props.studentViewPath}
                questionViewPath={this.props.questionViewPath}
                modelType={this.props.currentStudentID ? 'student' : 'question'}
                displayAutoGradedToggle={this.props.displayAutoGradedToggle}
                unitId={this.props.unitId}
              />
            )}
          </div>
          <div>
            {this._renderAllScoreFields()}
            {this._renderStudentProgress()}
          </div>
        </div>
        {this._renderAssessmentInProgressWarning()}
      </>
    );
  }

  extraExerciseContext() {
    return (
      <div>
        {this._renderStudentGradingProgress()}
        {!this.props.gradingGame && this._renderQuestionGradingProgress()}
        {!this.props.gradingGame && !this.props.gradingSlideshow && this._renderSectionScoreFields()}
        {this.props.gradingSlideshow && this._renderSlideGroupScoreFields()}
        {!this.props.unitId && this.bulkGradingButton()}
      </div>
    );
  }

  oldHeaderFormat() {
    return (
      <div id="flex-grading-header">
        <div className="left-column">
          {this._renderTitle()}
          <div className={styles.pagingButtons}>
            {this._renderPager()}
            <div className={styles.classroomSelect}>
              {this._renderClassroomToggle()}
            </div>
          </div>
          {!this.props.gradingGame && this.extraNavigation()}
        </div>
        {this.extraExerciseContext()}
      </div>
    );
  }

  render() {
    if (this.props.userType === 'student') {
      return (
        <React.Fragment>
          {this._renderStudentGradingProgress()}
          {this._renderAllScoreFields()}
          {this._renderQuestionGradingProgress()}
        </React.Fragment>
      );
    }

    return this.props.updatedGradesTable ? (
      <div id="flex-grading-header">
        <div className="left-column">
          {this._renderTitle()}
          <div className="tw-flex tw-justify-between tw-items-top">
            {!this.props.gradingGame && this.extraNavigation()}
            {this.extraExerciseContext()}
          </div>
        </div>
      </div>
    ) : this.oldHeaderFormat();
  }
}
