import React from 'react';
import useLessonOverview from '@/hooks/api/useLessonOverview';
import Header from '@/components/shared/NewLessonOverview/Header';
import DiveDeeper from './DiveDeeper';
import Libraries from './Libraries';

type NewLessonOverViewProps = {
  programId: number,
  lessonId: number,
  stafferId: number,
  locale: string,
  unitColor: string,
};

const NewLessonOverview = ({ programId, lessonId, locale, stafferId, unitColor }: NewLessonOverViewProps) => {
  const { lesson, program, sections, slideShows, diveDeeper, isLoading } = useLessonOverview({ programId, lessonId, locale });

  if (isLoading) return null;

  return (
    <>
      <Header lessonTitle={lesson?.title} lessonPosition={lesson?.titleNumber} unitColor={unitColor} />
      <footer className="tw-w-full tw-h-[300px] tw-bg-light-grey tw-flex">
        <DiveDeeper
          sections={sections}
          stafferId={stafferId}
          programId={programId}
          lessonId={lessonId}
          diveDeeper={diveDeeper}
        />
        <Libraries />
      </footer>
    </>
  );
};

export default NewLessonOverview;
