import { useEffect, useState } from 'react';
import { FilterType, TagType } from '@/components/shared/Correlations/types';

interface UseFilterDataProps {
  filterData: TagType[];
}
const useFilterData = ({ filterData }: UseFilterDataProps) => {
  const [filters, setFilters] = useState({});

  const getFilters = () => {
    const filterMap: FilterType = {};

    filterData.forEach((tag) => {
      if (tag.lessons) {
        tag.lessons.forEach((lesson) => {
          if (!lesson.unitTitle) return;

          const unitTitle = `U${lesson.unitPosition} - ${lesson.unitTitle}`;
          const lessonTitle = lesson.titleNumber ? `L${lesson.titleNumber} - ${lesson.title}` : lesson.title;

          if (!filterMap[unitTitle]) {
            filterMap[unitTitle] = [];
          }

          if (filterMap[unitTitle].find(l => l.value === lesson.id)) return;

          filterMap[unitTitle].push({
            checked: false,
            label: lessonTitle,
            position: lesson.titleNumber,
            value: lesson.id,
          });
        });
      }
    });

    Object.keys(filterMap).forEach((unitTitle) => {
      filterMap[unitTitle].sort((a, b) => {
        const posA = a.position != null ? a.position : Number.MAX_SAFE_INTEGER;
        const posB = b.position != null ? b.position : Number.MAX_SAFE_INTEGER;

        return posA - posB;
      });
    });

    return filterMap;
  };


  useEffect(() => {
    if (filterData.length === 0) return;

    setFilters(getFilters());
  }, [filterData]);

  return { filters, setFilters };
};

export default useFilterData;
