import React from 'react';
import readingIcon from '@/../images/icons/reading.svg';
import { IReferenceMaterial } from '@/types/api/DiveDeeper';
import * as Routes from '@/modules/routes';
import I18n from 'i18n-js';
import { referenceMaterialLabel } from '../DiveDeeper.utils';

type CardProps = {
  programId? : number;
  referenceMaterial? : IReferenceMaterial;
};

const ReferenceMaterialCard = ({ programId, referenceMaterial } : CardProps) => {
  const { i18Key, tag } = referenceMaterialLabel(referenceMaterial.type);

  return (
    <div className="tw-mb-[1rem] tw-border tw-border-solid tw-border-med-grey tw-shadow-lg tw-w-full tw-rounded-lg
        tw-h-[4.5rem] tw-flex tw-justify-start tw-items-center"
    >
      <div className="tw-flex tw-justify-center tw-items-center tw-rounded-lg tw-bg-blue tw-min-h-[2.5rem]
          tw-min-w-[2.5rem] tw-mx-[1rem]"
      >
        <img className="tw-invert" src={readingIcon} alt="book-icon" />
      </div>
      <a href={referenceMaterial.href} className="tw-text-[16px] tw-mb-0 tw-text-tci-purple tw-w-[67%] tw-mr-auto">
        {`${I18n.t(i18Key)}: ${referenceMaterial.label}`}
      </a>
    </div>
  );
};
export default ReferenceMaterialCard;
