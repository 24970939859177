import React from 'react';
import I18n from '@/modules/i18n';
import { Button } from 'react-aria-components';
import Tooltip from '@/components/common/Tooltip';
import { Collapse } from '../../../../images/react/Collapse';
import { Expand } from '../../../../images/react/Expand';
import { HEADER_BUTTON_CLASSES } from '@/components/staffer/UnitPlanningDrawer/utils';

interface ExpandCollapseButtonProps {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExpandCollapseButton = ({ isExpanded, setIsExpanded }: ExpandCollapseButtonProps) => (
  <Tooltip content={I18n.t(`${isExpanded ? 'exit' : 'enter'}_full_screen`)} interactive={false}>
    <Button
      aria-label={I18n.t(`${isExpanded ? 'exit' : 'enter'}_full_screen`)}
      className={HEADER_BUTTON_CLASSES}
      onPress={() => setIsExpanded(prev => !prev)}
      type="button"
    >
      {isExpanded ? <Collapse fill={null} /> : <Expand fill={null} />}
    </Button>
  </Tooltip>
);
