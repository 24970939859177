import React, { useEffect, useState } from 'react';
import * as Routes from '@/modules/routes';
import axios from 'axios';
import Spinner from '@/components/common/Spinner';
import I18n from 'i18n-js';
import { useModalManager } from 'components/common/Modal';
import TableOfContentsModal from './TableOfContentsModal';
import UnitCard from '../Cards/UnitCard';
import styles from './index.module.scss';

interface UnitSectionProps {
  programId: number;
}

const UnitSection = ({ programId }: UnitSectionProps) => {
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const modalManager = useModalManager();

  useEffect(() => {
    const route = Routes.plato_api_program_units_path(programId);

    axios.get(route)
      .then((response) => {
        setLoading(false);
        setUnits(response.data.data);
      });
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className={styles.unitCardsContainer}>
      <div className="tw-pb-1 tw-py-4 tw-flex tw-items-center tw-justify-between">
        <div className="tw-font-bold tw-text-2xl">{I18n.t('units')}</div>
        <button
          className="tw-ml-4 tw-bg-inherit tw-border-none tw-cursor-pointer tw-flex tw-text-[#522e91] tw-font-[inherit] tw-text-[14px] tw-items-center"
          type="button"
          onClick={modalManager.open}
        >
          <i className="fa fa-icon fa-list-ul tw-text-xl" />
          <div className="tw-flex tw-items-center tw-ml-1.5 hover:tw-underline">
            {I18n.t('table_of_contents')}
          </div>
        </button>
        {modalManager.isOpen && (
          <TableOfContentsModal
            isOpen={modalManager.isOpen}
            closeModal={modalManager.close}
            units={units}
          />
        )}
      </div>
      <div className={styles.unitCards}>
        {units.map(unit => (
          <UnitCard key={unit.id} unit={unit} />
        ))}
      </div>
    </div>
  );
};

export default UnitSection;
